import { useState } from 'react';
import Image from 'next/image';

interface ImageLoaderProps {
    src: string;
    width?: number;
    height?: number;
    quality?: number;
}

export interface Props extends ImageLoaderProps {
    alt: string;
    className?: string;
}

const defaultSize = 24;
const availableSizes = [24, 48, 96, 120, 240, 400];

const getImageSize = (width?: number): number => {
    //use image default size when no size is provided.
    if (!width) return defaultSize;

    return availableSizes.reduce((prev, curr) => {
        return Math.abs(curr - width) < Math.abs(prev - width) ? curr : prev;
    });
};

const viLoader = (props: ImageLoaderProps) => {
    return `https://vi.cdn.pxr.nl/.imaging/mte/vinl-website-theme/${getImageSize(props.width)}/dam/assets/${
        props.src
    }/jcr:content/1515.png`;
};

const fallbackLoader = (props: ImageLoaderProps) => {
    return `https://vi.cdn.pxr.nl/.imaging/mte/vinl-website-theme/${getImageSize(
        props.width,
    )}/dam/assets/site-elements/logo_placeholder.png/jcr:content/logo_placeholder.png`;
};

export const ViImage = ({ alt, className, src, width, height }: Props) => {
    const [useFallback, setUseFallback] = useState<boolean>(false);

    return (
        <span className={className}>
            <Image
                loader={useFallback ? fallbackLoader : viLoader}
                src={src}
                alt={alt}
                width={width || height}
                height={height || width}
                onError={() => {
                    setUseFallback(true);
                }}
            />
        </span>
    );
};
