import { ReactElement, useEffect, useRef, useState } from 'react';

import { useTranslation } from '@cms/i18n/client';
import { Icon } from '@common/atoms/Icon';
import { BrandedHeading } from '@web/atoms/BrandedHeading';
import { InternalLink, InternalLinkProps } from '@web/atoms/InternalLink';
import { Item } from '@web/molecules/MainDropdown/types';

import { MainDropdown } from '../MainDropdown';
import { useTabbedContentThemeConfig } from './TabbedContent.config';

import styles from './TabbedContent.module.scss';

export interface Tab {
    title: string;
    content: ReactElement;
    isSelected?: boolean;
}

export interface Props {
    title?: string;
    subtitle?: string | false;
    isPrimary?: boolean;
    isSlider?: boolean;
    tabs: Tab[];
    defaultTabIndex?: number;
    dropDownItems?: Item[];
    defaultDropDownItem?: Item;
    onChangeDropdown?: (selection: Item, tabIndex: number) => Promise<void>;
    link?: InternalLinkProps;
}

export const TabbedContent = ({
    tabs,
    dropDownItems,
    defaultDropDownItem,
    isPrimary,
    isSlider,
    onChangeDropdown,
    subtitle: initialSubtitle,
    title,
    link,
    defaultTabIndex = 0,
}: Props) => {
    const themeConfig = useTabbedContentThemeConfig();
    const __actions = useTranslation('actions').t;
    const [activeTab, setActiveTab] = useState<number>(() => {
        const selectedIndex = tabs.findIndex((tab) => tab.isSelected);
        return selectedIndex !== -1 ? selectedIndex : defaultTabIndex;
    });
    const currentTabTitleRef = useRef<string | null>(tabs[activeTab]?.title || null);
    const classes = [styles.TabbedContent, isPrimary ? styles.primary : ''].filter(Boolean);

    useEffect(() => {
        if (currentTabTitleRef.current) {
            const currentTabIndex = tabs.findIndex((tab) => tab.title === currentTabTitleRef.current);
            setActiveTab(currentTabIndex !== -1 ? currentTabIndex : defaultTabIndex);
            return;
        }

        const selectedIndex = tabs.findIndex((tab) => tab.isSelected);
        setActiveTab(selectedIndex !== -1 ? selectedIndex : defaultTabIndex);
    }, [tabs, defaultTabIndex]);

    useEffect(() => {
        currentTabTitleRef.current = tabs[activeTab]?.title || null;
    }, [activeTab, tabs]);

    const handleDropdownChange = async (selection: Item, tabIndex: number) => {
        if (onChangeDropdown) {
            await onChangeDropdown(selection, tabIndex);
        }
    };

    const onClick = (type: string) => {
        const isNext = type === 'next';
        const newIndex = activeTab + (isNext ? 1 : -1);
        if (newIndex >= 0 && newIndex < tabs.length) {
            setActiveTab(newIndex);
        }
    };

    const subtitle = tabs.length < 2 && initialSubtitle !== false ? tabs[0]?.title : initialSubtitle;
    const slider: string = isSlider ? styles.Slider : '';

    return (
        <section key={`tabbed-content-${title}`} className={classes.join(' ')}>
            {subtitle || title ? (
                <header>
                    <div className={styles['tabbed-content-title']}>
                        {subtitle ? <h3>{subtitle}</h3> : null}
                        {title ? (
                            <BrandedHeading
                                as="h2"
                                className={styles['title']}
                                variant={themeConfig.brandedHeadingVariant}
                                accentColor={themeConfig.brandedHeadingAccentColor}
                            >
                                {title}
                            </BrandedHeading>
                        ) : null}
                    </div>
                    {link ? (
                        <InternalLink {...link}>
                            {__actions`more`}
                            <Icon.right />
                        </InternalLink>
                    ) : null}
                    {dropDownItems?.length ? (
                        <MainDropdown
                            onChangeDropdown={(selection) => handleDropdownChange(selection, activeTab)}
                            items={dropDownItems || []}
                            defaultItem={defaultDropDownItem}
                        />
                    ) : null}
                </header>
            ) : null}
            {tabs.length > 1 ? (
                <>
                    <ul className={styles.tabs + ' inverted ' + slider}>
                        <div onClick={() => onClick('prev')} className={activeTab > 0 ? '' : styles.hidden}>
                            <Icon.left />
                        </div>
                        {tabs.map((tab, i) =>
                            tab.title ? (
                                <li
                                    key={i}
                                    className={activeTab === i ? styles.active : ''}
                                    onClick={() => setActiveTab(i)}
                                >
                                    {tab.title}
                                </li>
                            ) : null,
                        )}
                        <div
                            onClick={() => onClick('next')}
                            className={activeTab < tabs.length - 1 ? '' : styles.hidden}
                        >
                            <Icon.right />
                        </div>
                    </ul>
                </>
            ) : null}
            <div className={styles['tab-wrapper']}>{tabs[activeTab]?.content}</div>
        </section>
    );
};
