import { Color } from '@common/types/Color';
import { BrandedHeadingVariant, Props as BrandedHeadingProps } from '@web/atoms/BrandedHeading';
import { ThemeConfig, useThemeConfig } from '@web/hooks/useThemeConfig';

export interface TabbedContentThemeConfig {
    brandedHeadingAccentColor: NonNullable<BrandedHeadingProps['accentColor']>;
    brandedHeadingVariant: NonNullable<BrandedHeadingProps['variant']>;
}

const THEME_CONFIG: ThemeConfig<TabbedContentThemeConfig> = {
    defaults: {
        brandedHeadingAccentColor: Color.TRANSPARENT_COLOR,
        brandedHeadingVariant: BrandedHeadingVariant.BORDER_BOTTOM,
    },
    byPlatform: {
        gp: {
            brandedHeadingAccentColor: Color.MAIN_COLOR,
        },
        vp: {
            brandedHeadingAccentColor: Color.FOURTH_COLOR,
        },
        vn: {
            brandedHeadingAccentColor: Color.FOURTH_COLOR,
        },
    },
};

export const useTabbedContentThemeConfig = () => {
    return useThemeConfig(THEME_CONFIG);
};
