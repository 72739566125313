import { ReactElement } from 'react';

import { Link } from '@common/atoms/Link';
import { WinsBlock } from '@web/atoms/match/WinsBlock';
import { ResponsiveToggle } from '@web/atoms/ResponsiveToggle';
import { ViImage } from '@web/atoms/ViImage';

import { Alignment, Columns, Row } from './types';

import styles from './StatsTable.module.scss';

interface Props {
    columns: Columns;
    data: Row[];
    recalculateRanks?: boolean;
}

export const TableBody = ({ columns, data, recalculateRanks }: Props) => {
    const rows: ReactElement[] = [];
    let lastKeyNumber: number = 0;

    data.forEach((value: Row, index) => {
        const tableColumns = Object.entries(columns).map(([key, column]) => {
            let _value = value[key];
            let logo: ReactElement = <></>;
            const classes = [];

            if ((key === 'name' || key === 'team') && (value['teamID'] || value['id'])) {
                const id = value['teamID'] ? value['teamID'] : value['id'];
                logo = (
                    <ViImage
                        src={'opta-images/clublogo/' + id + '.png'}
                        alt="logo"
                        width={24}
                        height={24}
                        className={styles.Logo}
                    />
                );
            }

            // Split fullname to highlight last name
            if (key === 'fullName') {
                classes.push(styles.name);

                if (typeof _value === 'string') {
                    // Get names and lastname to only highlight
                    const names = _value.split(' ');
                    const lastName = names[names.length - 1];

                    // Remove last item from array
                    names.pop();

                    const items: string[] = [];
                    names.forEach((name) => {
                        items.push(name);
                    });

                    const fullName = (
                        <>
                            {items.join(' ')}
                            <strong> {lastName}</strong>
                        </>
                    );

                    _value = <ResponsiveToggle mobile={value['shortName'] ?? fullName} desktop={fullName} />;
                }

                if (value?.url) {
                    _value = <Link href={value?.url as string}>{_value}</Link>;
                }
            }

            // Add border for name and fullName
            if (key === 'name' || key === 'fullName' || key === 'team') {
                _value = (
                    <span
                        className={styles.content}
                        style={
                            value['color']
                                ? { borderLeftColor: value['color'].toString() }
                                : { borderLeftColor: 'transparent' }
                        }
                    >
                        {_value}
                    </span>
                );
            }

            if (key === 'team') {
                // Highlight if name is not present
                if (!value['name']) {
                    _value = <strong>{_value}</strong>;
                }

                if (value?.url) {
                    _value = <Link href={value?.url as string}>{_value}</Link>;
                }
            }

            // Display wins stat block
            if (key === 'stats') {
                return (
                    <td className={column.class} key={key}>
                        <WinsBlock matches={[]} />
                    </td>
                );
            }

            if (key === 'rank') {
                const tagClasses = ['tag', 'counter', styles.counter];

                if (column.class) tagClasses.push(column.class);

                // will check if rank is not set but the alternative column value 'counterTag' is set
                // counterTag will be used as rank but will never highlight the heighest rank
                if (!_value || value['counterTag']) {
                    _value = value['counterTag'];
                } else {
                    tagClasses.push(styles.rank);
                }

                if (_value === null || _value === undefined) {
                    tagClasses.push('disabled');
                    _value = '-';
                } else if (_value === lastKeyNumber) {
                    tagClasses.push('disabled');
                    _value = '';
                } else if (recalculateRanks) {
                    lastKeyNumber++;
                    _value = lastKeyNumber;
                }

                _value = <span className={tagClasses.join(' ')}>{_value}</span>;
            }

            if (_value === null || _value === undefined) {
                _value = '-';
            }

            if (column.class) classes.push(column.class);
            switch (column.alignment) {
                case Alignment.Left:
                    classes.push(styles.left);
                    break;
                case Alignment.Right:
                    classes.push(styles.right);
                    break;
                case Alignment.Centered:
                    classes.push(styles.centered);
                    break;
            }

            if (column.hideOnMobile) classes.push(styles['hide-on-mobile']);

            return (
                <td key={`${index}-${key}`} className={classes.join(' ')}>
                    {logo}
                    {_value}
                </td>
            );
        });

        const rowClasses = [];
        if (value.highlighted) {
            rowClasses.push(styles.highlighted);
        }

        rows.push(
            <tr key={index} className={rowClasses.join(' ')}>
                {tableColumns}
            </tr>,
        );
    });

    return <tbody>{rows}</tbody>;
};
