import { TableBody } from './TableBody';
import { TableFooter } from './TableFooter';
import { TableHead } from './TableHead';
import { Columns, Row } from './types';

import styles from './StatsTable.module.scss';

export interface Props {
    columns: Columns;
    data: Row[];
    total: Row;
    recalculateRanks?: boolean;
}

export const StatsTable = ({ columns, data, total, recalculateRanks }: Props) => {
    return (
        <table className={styles.StatsTable}>
            <TableHead columns={columns} />
            <TableBody columns={columns} data={data} recalculateRanks={recalculateRanks} />
            <TableFooter total={total} columns={columns} />
        </table>
    );
};
