import { Columns, Row } from './types';

import styles from './StatsTable.module.scss';

interface Props {
    columns: Columns;
    total: Row;
}

export const TableFooter = ({ columns, total }: Props) => {
    const tableColumns = Object.entries(columns).map(([key, column]) => {
        const value = total[key];

        if (!value) {
            return null;
        }

        const classes = [];
        if (column.hideOnMobile) classes.push(styles['hide-on-mobile']);

        return <td key={key}>{value}</td>;
    });

    return (
        <tfoot>
            <tr>{tableColumns}</tr>
        </tfoot>
    );
};
